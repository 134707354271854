
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import HowToUse from '../../components/HowToUse';
import DownloadBadge from '../../components/DownloadBadge';
import { genHowToUseBreadcrumbJsonLd } from '../../utils/jsonld';

const meta = {
  en: {
    title: 'Beautify and highlight code in Evernote - EverTool',
    subtitle: 'Engineers can start to beautify and highlight code in Evernote',
  },
  'zh-tw': {
    title: 'Evernote 美化程式碼 - EverTool',
    subtitle: '工程師可以開始在 Evernote 美化程式碼、撰寫技術筆記',
  },
  'zh-cn': {
    title: '印象笔记美化程式码 - EverTool',
    subtitle: '程序员可以开始在 Evernote 美化程式码、撰写技术笔记',
  },
  ja: {
    title: '美化し、Evernoteの中のハイライトコード - EverTool',
    subtitle: 'エンジニアは美化し、Evernoteの中のハイライトコードを開始することができます',
  },
};


class BeautifyCodePage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const config = {
      images: {
        mac: {
          intro1: [data.mac_intro1],
          intro2: [data.mac_intro2],
          intro3: [data.mac_intro3],
        },
      },
      introId: {
        title: 'code.title',
        step1: 'code.step1',
        step2: 'code.step2',
        step3: 'code.step3',
      },
    };
    this.state = {
      config,
    };
  }

  render() {
    const { pageContext, data } = this.props;
    const { config } = this.state;
    const { locale, url } = pageContext;

    return (
      <Layout
        pageContext={pageContext}
        seo={{
          title: meta[locale].title,
          subtitle: meta[locale].subtitle,
          hreflangs: pageContext.hreflangs,
          url,
          locale,
          jsonld: genHowToUseBreadcrumbJsonLd(meta[locale].title, url, locale),
        }}
      >
        <div className="how-to-wrapper">
          <p className="center">Release in version 0.4.0 and later</p>
          <HowToUse
            locale={locale}
            pageContext={pageContext}
            url={url}
            config={config}
            oneSystem="mac"
          >
            <hr />
            <b className="is-size-4"><FormattedMessage id="code.tip.title1" /></b>
            <p className="is-size-5">
              1.
              <FormattedMessage id="code.tip.tip1" />
            </p>
            <Img fluid={data.mac_lang1.childImageSharp.fluid} style={{ maxWidth: '400px', margin: '0 auto' }} />
            <p className="is-size-5">
              2.
              <FormattedMessage id="code.tip.tip2" />
            </p>
            <p>
              <FormattedMessage id="code.tip.tip2.desc" />
            </p>
            <Img fluid={data.mac_lang2.childImageSharp.fluid} style={{ maxWidth: '300px', margin: '0 auto' }} />
            <DownloadBadge />
          </HowToUse>
        </div>
      </Layout>
    );
  }
}

export default BeautifyCodePage;


export const pageQuery = graphql`
  query HowToBeautifyCodeQuery {
    site {
      siteMetadata {
        title
        subtitle
        url
      }
    }
    mac_intro1: file(relativePath: { eq: "how-to/beautify-code/basic/mac/intro-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_intro2: file(relativePath: { eq: "how-to/beautify-code/basic/mac/intro-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_intro3: file(relativePath: { eq: "how-to/beautify-code/basic/mac/intro-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_lang1: file(relativePath: { eq: "how-to/beautify-code/default-lang/mac/github-flavor.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_lang2: file(relativePath: { eq: "how-to/beautify-code/default-lang/mac/default.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
