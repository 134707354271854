import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';

import Wrapper from './Wrapper';
import { macOrWindows } from '../../utils';
import DownloadBadge from '../DownloadBadge';
import './index.css';

class HowToUse extends Component {
  static defaultProps = {
    imageStyle: {
      step1: '500px',
      step2: '500px',
      step3: '700px',
    },
  }

  constructor(props) {
    super(props);
    this.state = {
      system: macOrWindows(),
    };
  }

  onChangeSystem = (system) => {
    this.setState({ system });
  }

  genImage = (images, style = {}) => (
    <div className="columns">
      {images.map(image => (
        <div className="column" key={image.childImageSharp.fluid.src}>
          <Img fluid={image.childImageSharp.fluid} style={style} />
        </div>
      ))}
    </div>
  )

  genPreload = images => images.map(image => <link rel="preload" key={image} href={image} as="image" />)

  render() {
    let { system } = this.state;
    const {
      locale, pageContext, config, oneSystem, children, imageStyle,
    } = this.props;
    const { images, introId } = config;
    if (oneSystem) {
      system = oneSystem;
    }
    return (
      <Wrapper pageContext={pageContext} locale={locale}>
        <div className="center">
          <h1 className="is-size-2 mb4">
            <FormattedMessage id={introId.title} />
          </h1>
          {!oneSystem
            ? (
              <div className="tabs is-toggle">
                <ul style={{ justifyContent: 'center' }}>
                  <li
                    className={system === 'mac' ? 'is-active' : ''}
                    onClick={() => this.onChangeSystem('mac')}
                  >
                    <a>
                      <span className="icon is-small">
                        <i className="fab fa-apple" aria-hidden="true" />
                      </span>
                      <span>MacOS</span>
                    </a>
                  </li>
                  <li
                    className={system === 'win' ? 'is-active' : ''}
                    onClick={() => this.onChangeSystem('win')}
                  >
                    <a>
                      <span className="icon is-small">
                        <i className="fab fa-windows" aria-hidden="true" />
                      </span>
                      <span>Windows</span>
                    </a>
                  </li>
                </ul>
              </div>
            )
            : null}
          <div className="container">
            <div className="content">
              <div className="style-block">
                <p className="is-size-5">
                  1.
                  {' '}
                  <FormattedMessage id={introId.step1} />
                </p>
                {this.genImage(images[system].intro1, { maxWidth: imageStyle.step1, margin: '0 auto' })}
              </div>
              <div className="style-block">
                <p className="is-size-5 mb3">
                  2.
                  {' '}
                  <FormattedMessage id={introId.step2} />
                </p>
                {this.genImage(images[system].intro2, { maxWidth: imageStyle.step2, margin: '0 auto' })}
              </div>
              <div className="style-block">
                <p className="is-size-5 mb3">
                  3.
                  {' '}
                  <FormattedMessage id={introId.step3} />
                </p>
                {this.genImage(images[system].intro3, { maxWidth: imageStyle.step3, margin: '0 auto' })}
              </div>

              <p className="is-size-6 has-text-centered mb3 mt4">
                <FormattedMessage
                  id="intro.congrat"
                  defaultMessage="Congrats! You can start to customize your font style collection."
                />
              </p>
            </div>
          </div>
          <DownloadBadge />
          {children || null}
        </div>
      </Wrapper>
    );
  }
}

export default HowToUse;
