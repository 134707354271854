import React from 'react';
import { IntlProvider } from 'react-intl';

import Footer from '../Footer';
import Header from '../Header';

import getMessages from '../../locales/getMessages';

const messages = getMessages();

const HowToUseWrapper = ({ children, pageContext }) => {
  const { locale } = pageContext;
  return (
    <div>
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          {children}
        </div>
        <Footer />
      </IntlProvider>
    </div>
  );
};

export default HowToUseWrapper;
